import Img from '../Img'
import PropTypes from 'prop-types'
import Hyperlink from '../HyperLink'
import { useTrustMarkers } from 'src/common/trust-markers'

const TrustPilotWidget = ({ rel, statusCode, endDescription = false }) => {
  const { getTrustMarkerById } = useTrustMarkers()
  const trustPilot = getTrustMarkerById('truspilot')

  const reviewsNumber = trustPilot?.totalReview
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')

  return (
    <Hyperlink
      href="https://www.trustpilot.com/review/freedomdebtrelief.com"
      target="_blank"
      rel={`noreferrer ${rel}`}
      clickTracking={true}
      clickTrackText="Trustpilot"
      statusCode={statusCode}
    >
      <div className="mx-auto flex w-fit flex-col">
        <div className="flex items-baseline">
          <Img
            src="/next-assets/trustpilot/big-star.png"
            alt="Trustpilot 4.5 star average rating on over 38,000 reviews for Freedom Debt Relief"
            className="top-0.5 h-7 w-8"
          />
          <span className="text-23 font-medium text-black-base">
            Trustpilot
          </span>
        </div>
        {!endDescription && (
          <small className="pb-1 text-left text-xxxs text-gray-940">
            Reviews {reviewsNumber} · Excellent
          </small>
        )}
        <div className="flex items-center">
          <Img
            priority
            src="/next-assets/trustpilot/trustpilot-rating.svg"
            alt="Trustpilot 4.5 star average rating on over 38,000 reviews for Freedom Debt Relief"
            className="mr-auto h-6 w-30"
          />
          <small className="pl-1 text-xxxs text-black-base">
            <b>{Number(trustPilot?.rating)}</b>
            {endDescription ? '/5' : ''}
          </small>
        </div>
        {endDescription && (
          <small className="w-full pt-1 text-center text-xxxs text-black-base">
            Reviews {reviewsNumber} · Excellent
          </small>
        )}
      </div>
    </Hyperlink>
  )
}

TrustPilotWidget.defaultProps = {
  rel: '',
  endDescription: false,
}

TrustPilotWidget.propTypes = {
  rel: PropTypes.string,
  statusCode: PropTypes.number,
  endDescription: PropTypes.bool,
}

export default TrustPilotWidget
